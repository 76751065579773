import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LayoutsComponent } from "./layouts/layouts.component";
import { LoginComponent } from "./login/login.component";
import { MerchantsComponent } from "./merchants/merchants.component";
import { ProfileComponent } from "./profile/profile.component";
import { RegisterComponent } from "./register/register.component";
import { ProductsComponent } from "./products/products.component";
import { MerchantaccountComponent } from './merchantaccount/merchantaccount.component';
import { MessagesComponent } from './messages/messages.component';

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "",
    component: LayoutsComponent,
    children: [
      { path: "dashboard", component: DashboardComponent },
      { path: "users", component: MerchantsComponent },
      { path: "profile", component: ProfileComponent },
      { path: "products", component: ProductsComponent },
      { path: "account", component: MerchantaccountComponent },
      { path: "message", component: MessagesComponent },
    ],
  },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "**", redirectTo: "/DashboardComponent" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
