export class Produit {
  id: string; //
  titre: string; //
  description: string;
  prix: number;
  vendeur: string;
  id_vd: string;
  dt: any;
  localite: string;
  categorie: string;
  reservant: string;
  tel: string;
  emplacement: string;
}
