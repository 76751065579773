import { Component, OnInit } from '@angular/core';
import { MessageService } from '../services/message.service';
import { UserService } from "../services/user.service";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentReference,
} from "@angular/fire/firestore";
export class Artisan {
  uid: any;
  displayName: String;

  category: string;

}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
messages: any
   data = { type: '', pseudo: '', message: '', date: '' };
  topic = { iduser: '', user: '', nomvd: '', username: '', recepteur: '', date: '' };
    topics: any;
  identifiant: string;
  room: string;
  pseudo: string;
  etat = false;
  message = '';
   artisans: Artisan[];
  datas: any;
  isShow: boolean;
  i = 0;
  evaluations = [0];
  constructor(private messageService: MessageService,
     private firestore: AngularFirestore,
    private userService: UserService) {
this.room = localStorage.getItem('room');
this.data.pseudo = localStorage.getItem('pseudo');

   }
  dtOptions: DataTables.Settings = {};

  ngOnInit() {
    this.liste();
    this.topiclist();
     let pr = JSON.parse(localStorage.getItem("users"));
    this.pseudo = pr.displayName;

    this.identifiant = pr.uid;

  }
  topiclist() {
 this.messageService.read_Topics().subscribe(data => {

      this.topics = data.map(e => {
        return {
         id: e.payload.doc.id,

         iduser: e.payload.doc.data()["iduser"],
         user: e.payload.doc.data()["user"],
         nomvd: e.payload.doc.data()["nomvd"],
         date: e.payload.doc.data()["date"],
         username: e.payload.doc.data()["username"],
         vendeur: e.payload.doc.data()["vendeur"],



        };
      });
      console.log(this.topics);

    });


   }

  liste() {


      this.datas = this.firestore.collection("users").snapshotChanges();
    this.datas.subscribe((artisan) => {
      this.artisans = artisan.map((item) => {
        let uid = item.payload.doc.id;
        let data = item.payload.doc.data();
        return { uid, ...(data as {}) } as Artisan;
      });
      console.log(this.artisans);
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 5,
        lengthMenu: [5, 10, 25],
        processing: true,
      };
      this.isShow = true;
    });
  }


CreateRecord() {
this.data.type = 'msg';

this.data.date = Date();
  this.data.pseudo = this.pseudo;
this.messageService.create_NewMessage(this.data, this.room).then(resp => {
      console.log(resp);
     // alert('ajouté avec succés!');
      // window.location.replace('topic');
      this.data.message = '';
    })
           .catch(error => {
             console.log(error);
           });
          }


  select(us) {
    localStorage.setItem("room", us);
    this.readmsg();


  }
  readmsg() {
   this.room=localStorage.getItem("room");
  let pr = JSON.parse(localStorage.getItem("users"));
    this.pseudo = pr.displayName;
    this.messageService.read_Messages(this.room).subscribe(data => {
      this.messages = data.map(e => {

        return {
         id: e.payload.doc.id,

         message: e.payload.doc.data()["message"],
         pseudo: e.payload.doc.data()["pseudo"],
         type: e.payload.doc.data()["type"],
         date: e.payload.doc.data()["date"],

        };
      }
      );
      console.log(this.messages);


    });

  }
   add(grp) {

     this.topic.date = Date();
     this.topic.iduser = localStorage.getItem("user");
     let pr = JSON.parse(localStorage.getItem("users"));
     this.topic.username = pr.displayName;
     this.topic.user = this.topic.iduser;
     this.topic.recepteur = grp.uid;
     this.topic.nomvd = grp.displayName;

    this.messageService.create_NewTopic(this.topic).then(resp => {
    console.log(resp);
    alert('ajouté avec succés!');
   // window.location.replace('topic');

       })
         .catch(error => {
           console.log(error);
         });
       }


}
