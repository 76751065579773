import { Component, OnInit } from "@angular/core";
import { User } from "../../classes/user";
import { UserService } from "../../services/user.service";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  cuser: any;
  users: any;
  user: User;
  uid: string;
  profile: string;
  days = 0;
  edit = false;
  userData: any; // Save logged in user data
  public cuse: any;
  constructor(
    public auth: AuthService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth,
    public userService: UserService
  ) {}
  ngOnInit(): void {}

  out() {
    this.auth.SignOut();
  }

  // Returns true when user is looged in and email is verified
}
