//import { PlanningService } from './../shared/services/planning.service';
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
//import { Planning } from '../shared/modeles/planning';
import { Subject } from "rxjs";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { UserService } from "../services/user.service";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentReference,
} from "@angular/fire/firestore";
export class Patient {
  uid: any;
  nom: String;
  prenom: String;
  date: String;
  displayName: string;
  email: String;
  password: String;
  phoneNumber: string;
  adresse: string;
  category: string;
  matricule: String;
  date_creation: Date;
}

@Component({
  selector: "app-merchants",
  templateUrl: "./merchants.component.html",
  styleUrls: ["./merchants.component.css"],
})
export class MerchantsComponent implements OnInit {
  patients: Patient[];
  data: any;
  isShow: boolean;
  i = 0;
  evaluations = [0];
  constructor(
    private firestore: AngularFirestore,
    private userservice: UserService
  ) {}

  dtOptions: DataTables.Settings = {};

  ngOnInit() {
    //Get Data from firebase Database
    this.data = this.firestore.collection("users").snapshotChanges();
    this.data.subscribe((patient) => {
      this.patients = patient.map((item) => {
        let uid = item.payload.doc.id;
        let data = item.payload.doc.data();
        return { uid, ...(data as {}) } as Patient;
      });
      console.log(this.patients);
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 5,
        lengthMenu: [5, 10, 25],
        processing: true,
      };
      this.isShow = true;
    });
  }
  evaluer(user,evaluation) {
    let x = user;
    x.evaluation = evaluation;

    let p = Object.assign({}, x);
    this.userservice.create_NewEvaluation(x);
    alert("evaluer avec succés");
   }
  
}
