import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Produit } from '../classes/produit';
import { AuthService } from '../services/auth.service';
import { Produitservice } from '../services/produit.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-merchantaccount',
  templateUrl: './merchantaccount.component.html',
  styleUrls: ['./merchantaccount.component.css']
})
export class MerchantaccountComponent implements OnInit {
  evals=[];
 produits: any;
  produit: Produit;
  state: any;
  users: any;
  profile: any;
  moyenne = 0;
  downloadURL: Observable<string>;
  selectedFile: File = null;
  fb = "";
  userData: any; // Save logged in user data
  cuse: any;
  selected: any;
  s = 0;
  public search: any = "";
  p: number = 1;
  ss = false;
  prod: Produit;
  reservations: any;
  constructor(
    public produitService: Produitservice,
    private storage: AngularFireStorage,
    public auth: AuthService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth,
    private userService:UserService
  ) {}

  ngOnInit() {
    this.produit = new Produit();
    this.prod = new Produit();

    this.getproduits();
    this.userdet();
    this.getUsers();
    this.evaluation()
  }
 getUsers() {
    this.userService.read_User().subscribe((data) => {
      this.users = data.map((e) => {
        return {
          uid: e.payload.doc.id,

          email: e.payload.doc.data()["email"],
          photoURL: e.payload.doc.data()["photoURL"],
          displayName: e.payload.doc.data()["displayName"],
          emailVerified: e.payload.doc.data()["emailVerified"],
          phoneNumber: e.payload.doc.data()["phoneNumber"],
          adresse: e.payload.doc.data()["adresse"],
          bio: e.payload.doc.data()["bio"],
          category: e.payload.doc.data()["category"],
        };
      });
      let cuser = localStorage.getItem("user");
      // console.log(this.users);
      for (let u of this.users) {
        if (cuser === u.uid) {
          this.profile = u;
          if (this.profile.category == null ||localStorage.getItem("category")=="undefined")
            window.location.replace("products");
          // console.log(this.profile);
        }
      }
    });
  }
  userdet() {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
          console.log("my datas:", JSON.stringify(this.userData));
        this.produit.vendeur = user.displayName;
        localStorage.setItem("users", JSON.stringify(this.userData));
        this.cuse = JSON.parse(localStorage.getItem("user"));



      } else {
        //localStorage.setItem("user", null);
        //JSON.parse(localStorage.getItem("user"));
        window.location.replace("login");
      }
    });

  }


  onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `${this.userData.displayName}/Products/`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(
      `${this.userData.displayName}/Products/`,
      file
    );
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe((url) => {
            if (url) {
              this.fb = url;
            }
            console.log(this.fb);
          });
        })
      )
      .subscribe((url) => {
        if (url) {
          console.log(url);
        }
      });
  }
  add() {
    this.produit.id_vd = localStorage.getItem("user");
    this.produit.dt = Date();
            this.cuse=JSON.parse(localStorage.getItem("users"));
    this.produit.vendeur = this.cuse.displayName;
    this.produit.categorie = localStorage.getItem("categorie")
    let pr = Object.assign({}, this.produit);
    this.produitService.create_NewProduit(pr);
    alert("ajouté avec succés");
    this.produit = new Produit();
    // window.location.reload();
  }
  up() {
    this.produit.id_vd = localStorage.getItem("user");
    this.produit.dt = Date();
    let pr = Object.assign({}, this.produit);
    this.produitService.update_Produit(this.produit.id, pr);
    alert("modifié avec succés");
    this.produit = new Produit();
    // window.location.reload();
  }
  getproduits() {
    this.produitService.read_mesProduits().subscribe((data) => {
      this.produits = data.map((e) => {
        return {
          id: e.payload.doc.id,

          // tslint:disable-next-line: no-string-literal
          titre: e.payload.doc.data()["titre"],
          // tslint:disable-next-line: no-string-literal
          prix: e.payload.doc.data()["prix"],
          localite: e.payload.doc.data()["localite"],
          // tslint:disable-next-line: no-string-literal

          // tslint:disable-next-line: no-string-literal
          description: e.payload.doc.data()["description"],

          // tslint:disable-next-line: no-string-literal
          dt: e.payload.doc.data()["dt"],
          categorie: e.payload.doc.data()["categorie"],
          // tslint:disable-next-line: no-string-literal
        };
      });
      console.log(this.produits);
    });
  }
  getreservation(id) {
    this.produitService.read_Reservation(id).subscribe((data) => {
      this.reservations = data.map((e) => {
        return {
          id: e.payload.doc.id,

          // tslint:disable-next-line: no-string-literal
          titre: e.payload.doc.data()["titre"],
          reservant: e.payload.doc.data()["reservant"],
          emplacement: e.payload.doc.data()["emplacement"],
          // tslint:disable-next-line: no-string-literal
          prix: e.payload.doc.data()["prix"],
          localite: e.payload.doc.data()["localite"],
          // tslint:disable-next-line: no-string-literal

          // tslint:disable-next-line: no-string-literal
          description: e.payload.doc.data()["description"],

          // tslint:disable-next-line: no-string-literal
          dt: e.payload.doc.data()["dt"],
          categorie: e.payload.doc.data()["categorie"],
          // tslint:disable-next-line: no-string-literal
        };
      });
      console.log(this.reservations);
    });
  }

  
  delete(id): void {
    if (confirm("êtes vous sûre de vouloir supprimer?")) {
      this.produitService.delete_Produit(id);
      //window.location.replace('gestion');
      this.selected = "";
    }
  }
  annuler() {
    this.produit = new Produit();
    this.selected = "";
    this.ss = false;
  }
  select(us) {
    this.ss = true;
    this.selected = us;
    this.prod = us;
    this.getreservation(this.prod.id)
    if (us.etat == "en cours") {
      this.state = true;
    } else this.state = false;
  }
  evaluation()
  {
     this.userService.read_One(localStorage.getItem("user")).subscribe((data) => {
      this.evals = data.map((e) => {
        return {
          id: e.payload.doc.id,

          // tslint:disable-next-line: no-string-literal
          nom: e.payload.doc.data()["displayName"],
          evaluation: e.payload.doc.data()["evaluation"],


        };
      });
       console.log(this.evals);
       for (let m of this.evals) {
         this.moyenne += m.evaluation / this.evals.length;


       }
       this.moyenne = Math.round(this.moyenne);
    });

  }
}
