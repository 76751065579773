import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LayoutsComponent } from "./layouts/layouts.component";
import { NavbarComponent } from "./layouts/navbar/navbar.component";
import { SidebarComponent } from "./layouts/sidebar/sidebar.component";
import { MerchantsComponent } from "./merchants/merchants.component";
import { DataTablesModule } from "angular-datatables";
import { RegisterComponent } from "./register/register.component";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreDocument } from "@angular/fire/firestore";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { FooterComponent } from "./layouts/footer/footer.component";
import { ProfileComponent } from "./profile/profile.component";
import { FormsModule } from "@angular/forms";
import { SearchPipe } from "./pipes/search.pipe";

import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask,
  StorageBucket
} from "@angular/fire/storage";
import { ProductsComponent } from './products/products.component';
import { MerchantaccountComponent } from './merchantaccount/merchantaccount.component';
import { MessagesComponent } from './messages/messages.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    LayoutsComponent,
    NavbarComponent,
    SidebarComponent,
    MerchantsComponent,
    RegisterComponent,
    FooterComponent,
        SearchPipe,

    ProfileComponent,
    ProductsComponent,
    MerchantaccountComponent,
    MessagesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    FormsModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
