import { Component, OnInit } from "@angular/core";
import { User } from "../classes/user";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { AngularFireStorage } from "@angular/fire/storage";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { Produitservice } from '../services/produit.service';
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  cuser: any;
  users: any;
  produits: any;
  user: User;
  uid: string;
  profile: any;
  days = 0;
  photo = "";
  downloadURL: Observable<string>;
  edit = false;
  userData: any; // Save logged in user data
  cuse: any;
  constructor(
    public auth: AuthService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private storage: AngularFireStorage,
    private produitService : Produitservice
  ) {}

  ngOnInit() {
    this.cuse = null;
    this.cuser = localStorage.getItem("user");
    console.log(this.cuser);
    //console.log("user: ", this.cuser.photoURL);
    this.user = new User();
    this.users = [];
    this.getUsers();
    this.userdet();
    this.getproduits();
  }
  userdet() {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        //  console.log("my datas:", JSON.stringify(this.userData));
        this.cuse = this.userData;
        let d = new Date();
        console.log("date: ", d);
        let jour = new Date(this.cuse.metadata.creationTime);
        console.log(jour.toUTCString());
        this.days += d.getDay() - jour.getDay();
        this.days += (d.getFullYear() - jour.getFullYear()) * 256;

        console.log("difference: ", this.days);
        // localStorage.setItem("user", JSON.stringify(this.userData));
        //JSON.parse(localStorage.getItem("user"));
      } else {
        //localStorage.setItem("user", null);
        //JSON.parse(localStorage.getItem("user"));
        window.location.replace("login");
      }
    });
  }
  ed() {
    this.edit = true;
  }
  onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `${this.profile.displayName}/Profile/`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(
      `${this.profile.displayName}/Profile/`,
      file
    );
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe((url) => {
            if (url) {
              this.photo = url;
              this.profile.photoURL = this.photo;
            }
            console.log(this.photo);
          });
        })
      )
      .subscribe((url) => {
        if (url) {
          console.log(url);
        }
      });
  }
  getUsers() {
    this.userService.read_User().subscribe((data) => {
      this.users = data.map((e) => {
        return {
          uid: e.payload.doc.id,

          email: e.payload.doc.data()["email"],
          photoURL: e.payload.doc.data()["photoURL"],
          displayName: e.payload.doc.data()["displayName"],
          emailVerified: e.payload.doc.data()["emailVerified"],
          phoneNumber: e.payload.doc.data()["phoneNumber"],
          adresse: e.payload.doc.data()["adresse"],
          bio: e.payload.doc.data()["bio"],
          category: e.payload.doc.data()["category"],
        };
      });
      // console.log(this.users);
      for (let u of this.users) {
        if (this.cuser === u.uid) {
      
          this.profile = u;
          // console.log(this.profile);
        }
      }
    });
  }
  registerUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );

    return userRef.set(this.userData, {
      merge: true,
    });
  }
  /*this.users = data.map((e) => {
        return {
          uid: e.payload.doc.id,

          email: e.payload.doc.data()["email"],
          photoURL: e.payload.doc.data()["photoURL"],
          displayName: e.payload.doc.data()["displayName"],
          password: e.payload.doc.data()["password"],
          emailVerified: e.payload.doc.data()["emailVerified"],
          phoneNumber: e.payload.doc.data()["phoneNumber"],
          adresse: e.payload.doc.data()["adresse"],
        };
      });
      // tslint:disable-next-line: align
    });
    */
  uptodate() {
    let pr = this.profile;
    pr.category = this.user.categorie;
    let p = Object.assign({}, pr);
    this.userService.update_User(this.profile.uid, p);
    this.edit = false;
  }
   getproduits() {
    this.produitService.read_cats().subscribe((data) => {
      this.produits = data.map((e) => {
        return {
          id: e.payload.doc.id,
          titre: e.payload.doc.data()["titre"],
          
        };
      });
      console.log(this.produits);
    });
  }
}
