import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { User } from "../classes/user";
import { Produitservice } from '../services/produit.service';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  constructor(public authService: AuthService,
      public produitService: Produitservice
  ) { }
    produits: any;

  user: User;
  password: string;
  ngOnInit() {
    this.user = new User();
    this.getproduits();
   // this.user.photoURL =
    //  "https://firebasestorage.googleapis.com/v0/b/paygate-2883d.appspot.com/o/90x90.jpg?alt=media&token=99d0cf7f-3b95-46c6-a501-673e69a92a9b";
  }
   getproduits() {
    this.produitService.read_cats().subscribe((data) => {
      this.produits = data.map((e) => {
        return {
          id: e.payload.doc.id,

          titre: e.payload.doc.data()["titre"],
          
        };
      });
      console.log(this.produits);
    });
  }
  signup() {
    this.authService.SignUp(
      this.user.email,
      this.user.password,
      this.user.displayName,
      this.user.photoURL,
      this.user.adresse,
      this.user.phoneNumber,
    );
    /*  let us = Object.assign({}, this.user);
    this.authService.create_NewUser(us);
    window.location.replace("login");*/
  }
}
