// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
apiKey: "AIzaSyBDhD7N0qvDnLOBn1oWuMyd4Xuj-ruRPlw",
    authDomain: "service-domicile-e2cc5.firebaseapp.com",
    databaseURL: "https://service-domicile-e2cc5.firebaseio.com",
    projectId: "service-domicile-e2cc5",
    storageBucket: "service-domicile-e2cc5.appspot.com",
    messagingSenderId: "1079261381064",
    appId: "1:1079261381064:web:bc01e4a9765e192d3040fd",
    measurementId: "G-00P3JNGMMS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
